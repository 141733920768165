import React from 'react';
import styled from 'styled-components'
import Img from 'gatsby-image'

function RawTestimonialSlide({children, className, icon, text, name, agency, jobTitle, title}) {
    return (
        <div className={className}>
           <div className="icon"><Img fluid={icon} /></div>
            <div className="content" dangerouslySetInnerHTML={{__html: text}}/>
            <div className="name"><strong style={{color:"#403d3d"}}>{name}</strong></div>
            <div style={{color:"#403d3d"}} className="meta-info">
                <span>{jobTitle}</span> - <span>{title}</span>
            </div>
        </div>
    );
}

const TestimonialSlide = styled(RawTestimonialSlide)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding:0 30px;
  .content {
    font-size:17px;
    margin:30px 0;
  }
  p {
    font-size:17px;
  }
  .icon {
    max-width:130px;
    width:100%;
    margin:0 auto;
  }
`

export default TestimonialSlide;

