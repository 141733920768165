import React from 'react';
import { motion } from "framer-motion"
import styled from "styled-components"
import {AnimatedHeroSectionTitle} from "../SectionTitle/styles"
import { ButtonWrapper} from "../Button/styles";

function Index({className, children}) {
    return (
        <motion.div className={className}>
            {children}
        </motion.div>
    );
}

const AnimatedWrapper = styled(Index) `
    display:flex;
    flex-direction: column;
    align-items: center;
  ${ButtonWrapper} {
    justify-content: center;
    @media screen and (min-width: 992px) {
      justify-content: flex-start;
    }
  }
    @media screen and (min-width: 992px) {
      align-items: flex-start;
    }
    justify-content:center;
    position: relative;
    min-height:100px;
   ${AnimatedHeroSectionTitle} {
     position:absolute;
   }
`

export default AnimatedWrapper;