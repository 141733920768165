import React, {useContext, useEffect, useRef} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { Container } from "../components/Container"
import {
    AboutSection, BerrySection,
    CallToAction,
    HeroSection, PortfolioBox, PortfolioBoxWrapper, PortfolioSection,
    ServiceBox,
    ServiceBoxWrapper,
    ServiceSection,
    TestimonialSection
} from "../components/HomePage/styles";
import {AnimatedHeroSectionTitle, HeroSectionTitle, SectionTitle} from "../components/SectionTitle/styles"
import Flamingo from "../images/flamingo.svg"
import Development from "../images/developmentSettings.svg"
import Marketing from "../images/marketingTriode.svg"
import Branding from "../images/brandingStar.svg"
import ThreeSixty from "../images/startup360.svg"
import Ecommerce from "../images/ecommerce.svg"
import Gosocial from "../images/gosocial.svg"
import CircleDots from "../images/logoHomeOut.svg"
import CircleF from "../images/logoHomeInside.svg"
import GreenBerry from "../images/berrySection.svg"
import {ButtonGf, ButtonLink, ButtonWrapper, PopupText} from "../components/Button/styles";
import { colors } from "../const/colors"
import { LinkForward} from "../components/LinkForward/styles";
import { IoCheckmark } from "react-icons/io5"
import {motion, useAnimation} from "framer-motion"
import AnimatedWrapper from "../components/AnimatedWrapper"
import AnimatedInViewWrapper from "../components/AnimatedInViewWrapper"
import DevelopmentVideo from "../images/development-video.gif"
import BrandingVideo from "../images/branding-video.gif"
import MarketingVideo from "../images/marketing-video.gif"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import {VideoBlock} from "../components/BerryPage/styles";
import TestimonialSlide from "../components/TestimonialSlide";
import {isEmpty, getSectionsData } from "../utils/helpers"

import localPageData from "../localization/homepage.json"
// import localData from "../localization/green-initiative.json";

function BlockBox({className, title , label, link, step, iconImage}) {
    return (
        <motion.div className={className}>
            <div><img src={iconImage} alt="flamingo dot"/></div>
            <div><span>{title}</span></div>
            <AnimatedInViewWrapper
                initial="hidden"
                variants={{
                    visible: {
                        y:0,
                        opacity:1,
                        transition: {
                            duration:.6,
                            delay: step * 0.3
                        }
                    },
                    hidden: {
                        y:"20px",
                        opacity:0,
                        transition: {
                            duration:.6
                        }
                    }
                }}>
                <LinkForward link={link} text={label} />
            </AnimatedInViewWrapper>
        </motion.div>
    )
}

const AboutBlockBox = styled(BlockBox)`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  min-width:150px;
  img {
    display: block;
    width:100%;
    min-height:50px;
  }
  @media screen and (min-width: 600px) {
    align-items: flex-start;
    justify-content: center;
  }
  > div:last-child {
    margin-top:10px;
  }
  div {
    margin-bottom:20px;
    &:nth-child(2) {
      margin-bottom:0px;
      span {
        font-weight:bold;
        color: ${colors.grayColor};
      }
    }
  }
  img {
    max-width:43px;
    margin:0;
  }
  a {
    text-decoration:none;
    color: ${colors.greenColor};
    display:flex;
    align-items: center;
    font-weight:bold;
  }
`
const AboutBlockBoxWrapper = styled.div`
  display:flex;
  justify-content: center;
  flex-wrap:wrap;
  align-items: center;
  @media screen and (min-width: 992px) {
    justify-content: space-between;
  }
  text-align:left;
  width:100%;
  ${AboutBlockBox} {
    width:32%;
  }
`
let heroOne = {
    // opacity:[1,1,1,0,0,0,1],
    opacity:[1,1,0,0,0,1],
    // y:["0px", "0px","0px","-100px", "100px","100px", "0px" ],
    // y:["30px", "30px", "-100px", "100px","100px", "30px"],
    y:["30px", "30px", "100px", "-100px","-100px", "30px"],
    transition: {
        duration:12,
        // times:[0,0.071,0.355,0.426,0.497,0.852,0.923],
        times:[0,0.18,0.216,0.252,0.828,0.864],
        repeat: Infinity
    }
}
let heroTwo = {
    opacity:[0,1,1,0,0,0],
    //y:["100px", "30px","30px", "-100px", "100px", "100px" ],
    y:["-100px", "30px","30px", "100px", "-100px", "-100px" ],
    transition: {
        duration:12,
        // times:[0.426,0.497,0.781,0.852,0.923,1],
        times:[0.18,0.216,0.396,0.432,0.468, 1],
        repeat: Infinity
    }
}
let heroThree = {
    opacity:[0,1,1,0,0,0],
    //y:["100px", "0px","0px", "-100px", "100px", "100px" ],
    y:["-100px", "0px","0px", "100px", "-100px", "-100px" ],
    transition: {
        duration:12,
        times:[0.396,0.432,0.612,0.648,0.684,1],
        repeat: Infinity
    }
}
let heroFour = {
    opacity:[0,1,1,0,0,0],
    // y:["100px", "30px","30px", "-100px", "100px", "100px" ],
    y:["-100px", "30px","30px", "100px", "-100px", "-100px" ],
    transition: {
        duration:12,
        times:[0.612,0.648,0.828,0.864,0.9, 1],
        repeat: Infinity
    }
}
const circlef_variants = {
    start: {
        rotate:[0,360],
        transition:{
            duration:28,
            times: [0,1],
            repeat:Infinity
        }
    }
}
// const flags = {
//     "en": localPageData.locales[0].value,
//     "es": localPageData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localPageData.locales[0].value
    },
    {
        name: "es",
        uri : localPageData.locales[1].value
    }
]
const IndexPage = ({data, location, pageContext}) => {
    const refContainer = useRef(null)
    const { lang } = pageContext


    // const meta = data.template.locales.find(l => l.iso === lang)

    // const meta = data.template.locales.find(l => l.iso === lang)


    const meta = data.template.locales.find(l => l.iso === lang)
    const metaStatic = localPageData.locales.find(l => l.iso === lang)


    // const {hero, berry, cabanner, packages,portfolio,services, testimonial} = getSectionsData(data.template.sections)
    const { hero, berry, cabanner, packages,portfolio,services, testimonial, about  } = getSectionsData(data.template.sections)

    return <Layout location={location} lang={pageContext.lang} flags={flags}>
        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <Container>
            <HeroSection>
                <div>
                    <AnimatedWrapper>
                        <AnimatedHeroSectionTitle opacity="0" animate={heroOne}>{localPageData.sec.hero.slideGroup.first[lang]}</AnimatedHeroSectionTitle>
                        <AnimatedHeroSectionTitle opacity="0" animate={heroTwo}>{localPageData.sec.hero.slideGroup.second[lang]}</AnimatedHeroSectionTitle>
                        <AnimatedHeroSectionTitle forChange opacity="0" animate={heroThree}>{localPageData.sec.hero.slideGroup.third[lang]}</AnimatedHeroSectionTitle>
                        <AnimatedHeroSectionTitle opacity="0" animate={heroFour}>{localPageData.sec.hero.slideGroup.fourth[lang]}</AnimatedHeroSectionTitle>
                    </AnimatedWrapper>
                    <h2 style={{fontSize: " clamp(2.5rem, 6vw, 3.5rem)", textTransform:"uppercase" ,color: colors.grayColor}}>{localPageData.sec.hero.slideGroup.staticPart[lang]}</h2>
                    <p>{!!hero ? hero.subtitle[lang] : 'neki tekst'}</p>
                    <ButtonWrapper screen="992px">
                        <ButtonGf onClick={e => refContainer.current.scrollIntoView({behavior: "smooth"})} color="white" background={colors.greenColor} bgHoverColor={colors.grayColor} hoverColor="white">{!!hero ? hero['buttons.button1'].value[lang]: "services"}</ButtonGf>
                        {/*<ButtonGf>Free consultation</ButtonGf>*/}
                        <div className="free-consultation">
                            <PopupText text={!!hero ? hero['buttons.button2'].value[lang] : "schedule"} url="https://calendly.com/tina-greenflamingo/30min" />
                        </div>
                    </ButtonWrapper>
                </div>
                <motion.div initial={{opacity:0, y:"100px"}} animate={{opacity:1, y:0, transition:{ duration:1, delay:.3}}} style={{maxWidth: "470px"}}>
                    <motion.img variants={circlef_variants} animate="start" src={CircleDots} alt=""/>
                    <img src={CircleF} alt=""/>
                </motion.div>
            </HeroSection>
        </Container>
        <Container>
            <AboutSection>
                <div>
                    <img src={Flamingo} alt="Flamingo"/>
                </div>
                <div>
                    <SectionTitle style={{maxWidth:"575px"}}>{!!about ? about.title[lang] : "Servie title"}</SectionTitle>
                    <p>{!!about ? about.subtitle[lang] : "Service subtitle"}</p>
                    <AboutBlockBoxWrapper>
                        <AboutBlockBox title={localPageData.sec.about.group.first.title[lang]} iconImage={Development} label={localPageData.sec.about.group.first.link.label[lang]} link={localPageData.sec.about.group.first.link.uri[lang]} step={1} />
                        <AboutBlockBox title={localPageData.sec.about.group.second.title[lang]} iconImage={Marketing} label={localPageData.sec.about.group.second.link.label[lang]} link={localPageData.sec.about.group.second.link.uri[lang]} step={2} />
                        <AboutBlockBox title={localPageData.sec.about.group.third.title[lang]} iconImage={Branding} label={localPageData.sec.about.group.third.link.label[lang]} link={localPageData.sec.about.group.third.link.uri[lang]} step={3} />
                    </AboutBlockBoxWrapper>
                </div>
            </AboutSection>
        </Container>
        <ServiceSection>
            <Container ref={refContainer}>
                <SectionTitle align="center">Our Selection of Service Packages</SectionTitle>
                {!!packages && packages.selectedModules &&
                <ServiceBoxWrapper>
                    {packages.selectedModules.map((sm, index) => {
                        return <ServiceBox key={index} img={sm.imageFile?.publicURL} title={sm.locales.find(l => l.iso === lang).title} subtitle={sm.locales.find(l => l.iso === lang).description}>
                            {/*<p>{sm.locales.find(l => l.iso === lang).content}</p>*/}
                            <div dangerouslySetInnerHTML={{__html: `${sm.additionalData[0].locales.find(l => l.iso === lang).value}`}} />
                        </ServiceBox>
                    })}
                    {/*<ServiceBox img={ThreeSixty} title="Start-Up 360°" subtitle="Introduction of a brand and its competitiveness">*/}
                    {/*    <p>Are you starting a new business? <br/> <br/> Our Startup package includes all you need!  </p>*/}
                    {/*</ServiceBox>*/}
                    {/*<ServiceBox img={Ecommerce} title="E-commerce" subtitle="Complete set up of and online store">*/}
                    {/*    <p>Are you looking to sell your products online? <br/> <br/> Show-off your selection with our E-commerce package!</p>*/}
                    {/*</ServiceBox>*/}
                    {/*<ServiceBox img={Gosocial} title="Go-Social" subtitle="Interaction with the target audience">*/}
                    {/*    <p>Are you determined to get closer to your audience? <br/> <br/> Our Go-Social package will get you there!</p>*/}
                    {/*</ServiceBox>*/}
                </ServiceBoxWrapper>
                }
                {!!cabanner && !isEmpty(cabanner) &&
                <CallToAction>
                    <h2>{cabanner.selectedPlugin.locales.find(l => l.iso === lang).title}</h2>
                    <p>{cabanner.selectedPlugin.locales.find(l => l.iso === lang).description}</p>
                    <ButtonLink width="150px" margin="auto" to={cabanner.selectedPlugin.link} background="white" color={colors.greenColor} hoverColor="white" bgHoverColor={colors.grayColor}>{cabanner.selectedPlugin.locales.find(l => l.iso === lang).linkLabel}</ButtonLink>
                </CallToAction>
                }
            </Container>
        </ServiceSection>
        {/*{!!berry && !isEmpty(berry) &&*/}
        <BerrySection>
            <Container>
                <div className="berry-content">
                    <div>
                        {/*<Img fluid={data.file.childImageSharp.fluid} />*/}
                        <img style={{display:"block"}} src={GreenBerry} alt="Green Berry"/>
                    </div>
                    <div>
                        <SectionTitle>{localPageData.sec.berry.title[lang]}</SectionTitle>
                        <p>{localPageData.sec.berry.description[lang]}</p>
                        <ul>
                            <li><IoCheckmark />{localPageData.sec.berry.group.first[lang]}</li>
                            <li><IoCheckmark />{localPageData.sec.berry.group.second[lang]}</li>
                            <li><IoCheckmark />{localPageData.sec.berry.group.third[lang]}</li>
                            <li><IoCheckmark />{localPageData.sec.berry.group.fourth[lang]}</li>
                            <li><IoCheckmark />{localPageData.sec.berry.group.fifth[lang]}</li>
                            <li><IoCheckmark />{localPageData.sec.berry.group.sixth[lang]}</li>
                        </ul>
                        <AnimatedInViewWrapper
                            initial="hidden"
                            variants={{
                                visible: {
                                    y:0,
                                    opacity:1,
                                    transition: {
                                        duration:.6
                                    }
                                },
                                hidden: {
                                    y:"20px",
                                    opacity:0,
                                    transition: {
                                        duration:.6
                                    }
                                }
                            }}>
                            <LinkForward link={localPageData.sec.berry.button.uri[lang]} text={localPageData.sec.berry.button.label[lang]} />
                        </AnimatedInViewWrapper>
                        {/*<div></div>*/}
                    </div>
                </div>
            </Container>
        </BerrySection>
         {/*}*/}

        <PortfolioSection>
            <Container>
                <SectionTitle align="center">{!!portfolio ? portfolio.title[lang] : "Portfolio title"}</SectionTitle>
                {!!portfolio && portfolio.selectedModules && <PortfolioBoxWrapper>
                    {portfolio.selectedModules.map((sm, index) => {
                        const loc = sm.category.locales.find(l => l.iso === lang)
                        return <PortfolioBox key={index}  title={loc?.title || ''} subtitle={loc?.description || ''} icon={sm.category?.imageFile?.childImageSharp.fluid} link={`/portfolio?s=${loc?.title?.toLowerCase().split(" ").join("-")}`} linkText="On the web" step={1} />
                    })}
                    {/*<PortfolioBox  title="Development" subtitle="Building tailored web and mobile solutions" icon={DevelopmentVideo} link="/portfolios?s=development" linkText="On the web" step={1} />*/}
                    {/*<PortfolioBox   title="Digital Marketing" subtitle="Creation and distribution of relevant content" icon={MarketingVideo} link="/portfolios?s=digital-marketing" linkText="Spread the word" step={2} />*/}
                    {/*<PortfolioBox   title="Branding" subtitle="Foundation and presentation of company's identity" icon={BrandingVideo} link="/portfolios?s=branding" linkText="Leave a mark" step={2} />*/}
                </PortfolioBoxWrapper>}
            </Container>
        </PortfolioSection>
        <TestimonialSection>
            <Container>
                <SectionTitle align="center">{!!testimonial ? testimonial.title[lang] : "Testimonial title"}</SectionTitle>
                { !!testimonial && testimonial.selectedModules &&
                    <VideoBlock>
                        <Carousel showIndicators={false} showStatus={false} showThumbs={false}>
                            {testimonial.selectedModules.map((slide, index) => {
                                const loc = slide.locales.find(l => l.iso === lang)
                                return <TestimonialSlide
                                    key={index}
                                    icon={slide.imageFile?.childImageSharp.fluid}
                                    name={slide.submitter}
                                    jobTitle={slide.submitterOccupation}
                                    agency={slide.companyName}
                                    title={loc.title}
                                    text={loc.content}
                                />
                            })}
                        </Carousel>
                    </VideoBlock>}
            </Container>
        </TestimonialSection>
    </Layout>
}

export const query = graphql`
    query($templateName: String! ) {
        template(name: {eq: $templateName}) {
            name
            locales {
                metaTitle
                metaDescription
                metaKeywords
                iso
            }
            sections {
                keyPrefix
                ... on SectionRegularGet {
                    __typename
                    items {
                        key
                        ... on DataItemTextGet {
                            __typename
                            locales {
                                iso
                                value
                            }
                        }
                        ... on DataItemAssetGet {
                            __typename
                            imageFile {
                                publicURL
                            }
                            value {
                                locales {
                                    alt
                                }
                            }
                        }
                        ... on DataItemLinkGet {
                            __typename
                            locales {
                                iso
                                value
                            }
                            slugs {
                                iso
                                value
                            }
                        }
                    }
                }
                ... on SectionModuleGet {
                    items {
                        ... on DataItemTextGet {
                            locales {
                                iso
                                value
                            }
                        }
                        ... on DataItemAssetGet {
                            value {
                                locales {
                                    alt
                                    title
                                    iso
                                    description
                                }
                            }
                        }
                        ... on DataItemLinkGet {
                            slugs {
                                iso
                                value
                            }
                            locales {
                                iso
                                value
                            }
                        }
                    }
                    selectedModules {
                        additionalData {
                            locales {
                                iso
                                value
                            }
                            key
                        }
                        locales {
                            description
                            content
                            title
                            value
                            iso
                        }
                        ... on CategoryModuleGet {
                            category {
                               locales {
                                  iso
                                  title
                                  description
                                }
                                 imageFile {
                                  childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                  }
                                }
                            }
                        }
                        ... on TestimonialGet {
                            locales {
                              value
                              title
                              iso
                              content
                              description
                            }
                            submitter
                            submitterOccupation
                            submitterUrl
                            type
                            imageFile {
                              childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                              }
                              publicURL
                            }
                            companyUrl
                            companyName
                          }
                        ... on OfferGet {
                            imageFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                    fixed {
                                        src
                                    }
                                }
                                publicURL
                            }
                        }
                    }
                }
                ... on SectionPluginGet {
                    selectedPlugin {
                      locales {
                        title
                        linkLabel
                        iso
                        description
                      }
                      ... on BannerGet {
                        link
                      }
                    }
                  }
                items {
                    key
                    ... on DataItemTextGet {
                        locales {
                            iso
                            value
                        }
                    }
                    ... on DataItemAssetGet {
                        imageFile {
                            publicURL
                        }
                        value {
                            locales {
                                iso
                                title
                                description
                                alt
                            }
                        }
                    }
                    ... on DataItemLinkGet {
                        slugs {
                            iso
                            value
                        }
                        locales {
                            iso
                            value
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
